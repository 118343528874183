<template>
  <div class="mainwrap questionnaire-edit" v-loading="loading">
    <div class="title">
      {{ title }}
      <span v-if="isLook">
        <el-button type="primary" plain @click="cancel">返回</el-button>
      </span>
    </div>
    <div class="formwrap">
      <el-form
          :model="formData"
          :rules="rules"
          ref="cardForm"
          label-width="190px"
          label-position="left"
      >
        <el-form-item label="會員等級：" prop="level">
          <el-select
              v-model="formData.level"
              placeholder="請選擇會員等級"
              :disabled="isLook"
          >
            <el-option
                v-for="item in levelList"
                :key="item.key"
                :label="item.value"
                :value="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <div style="display: flex;align-items: center">
          <div style="width: 420px;">
            <el-form-item label="升等條件-消費金額起：" prop="paymentAmount">
              <el-input type="number" placeholder="請輸入升等條件-消費金額起" :disabled="isLook" v-model="formData.paymentAmount" controls-position="right" :min="1" :max="10000000000"></el-input>
            </el-form-item>
            <el-form-item label="升等條件-消費次數：" prop="paymentNum">
              <el-input type="number" placeholder="請輸入升等條件-消費次數" :disabled="isLook" v-model="formData.paymentNum" controls-position="right" :min="1" :max="10000000000"></el-input>
            </el-form-item>
            <p style="font-size: 12px;color: #606266;margin-top: -18px;margin-left: 190px;">消費次數为0免累計次數</p>
          </div>
          <span style="display: block;margin: 0 20px;height: 42px;">或</span>
          <div style="width: 420px;">
            <el-form-item label="升等條件-單次消費金額：" prop="singlePaymentAmount">
              <el-input type="number" placeholder="請輸入升等條件-單次消費金額" :disabled="isLook" v-model="formData.singlePaymentAmount" controls-position="right" :min="1" :max="10000000000"></el-input>
              <!--          <span>{{formData.conditionType === 0 ?'次': '元'}}</span>-->
            </el-form-item>
          </div>
        </div>
        <el-divider></el-divider>
        <el-form-item class="table_jl" label="升等獎勵：" prop="upgradeRewardItemDtos">
          <el-button style="margin-bottom: 20px;" :disabled="isLook" @click="addPrize()">新增獎項</el-button>
<!--          <span class="title_tip">比例獎項(總數需是100)</span>-->
          <el-table
              :data="formData.upgradeRewardItemDtos"
              style="width: 100%">
            <el-table-column
                prop="type"
                label="獎品內容">
              <template slot-scope="scope">
                <el-select :disabled="isLook"
                    @change="selectType(scope.row,scope.$index)"
                    v-model="scope.row.type"
                    placeholder="請選擇獎品"
                >
                  <el-option
                      v-for="item in prizeList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                  ></el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column
                prop="value"
                label="點數">
              <template slot-scope="scope">
                <el-input type="number" :disabled="isLook" v-if="scope.row.type === 1" label="請輸入點數" v-model="scope.row.value" controls-position="right" :min="1" :max="10000000000"></el-input>
              </template>
            </el-table-column>
            <el-table-column
                prop="rewardName"
                label="優惠券">
              <template slot-scope="scope">
                <el-select :disabled="isLook"
                    v-if="scope.row.type === 2"
                    v-model="scope.row.value"
                    placeholder="請選擇優惠券"
                >
                  <el-option
                      v-for="item in couponList"
                      :key="item.key"
                      :label="item.value"
                      :value="item.key"
                  ></el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column
                fixed="right"
                label="操作"
                width="120">
              <template slot-scope="scope">
                <el-button :disabled="isLook"
                    @click.native.prevent="deleteRow(scope.$index, formData.upgradeRewardItemDtos)"
                    type="text"
                    size="small">
                  移除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-divider></el-divider>
        <el-form-item label="啓用狀態" prop="isEnable">
          <el-switch :disabled="isLook" v-model="formData.isEnable"></el-switch>
        </el-form-item>
      </el-form>
    </div>

    <div class="btnwrap" v-if="!isLook">
      <div class="btnlist">
        <el-button type="primary" @click="onSubmit">提交</el-button>
        <el-button @click="cancel">取消</el-button>
      </div>
    </div>

  </div>
</template>

<script>
import * as api from "./api";
import { tableDataPagination } from "@/utils/common";
import {
  editGame,
  gameActivityDetail,
  getGameCoupon,
  getMemberLevelTypeList,
  memberLevelSetCouponList, memberLevelSetDetail,
  memberLevelTypeList, putMemberLevelSet
} from "./api";
import {getImageInfo} from "../../utils/common";

export default {
  data() {
    const validateLevel = (rule, value, callback) => {
      const upgradeRewardItem = this.formData.upgradeRewardItemDtos;
      let isTrue = false;
      upgradeRewardItem?.forEach((item)=>{
        isTrue = true;
        if(item.type && item.value){
          isTrue = false
        }
      })
      if (isTrue) {
        callback(new Error("請輸入升等獎勵"));
      } else {
        callback();
      }
    };
    return {
      levelList: [],
      prizeList: [{
        value: 1,
        label: "點數"
      },{
        value: 2,
        label: "優惠券"
      },],//獎項選擇
      loading: false,
      title: "",
      type: "",
      formData: {
        id: "",
        level: "", // 等级
        paymentAmount: "", // 升等條件-消費金額起
        singlePaymentAmount: "",//升等條件-單次消費金額
        paymentNum: null,//升等條件-消費次數
        isEnable: true, // 是否啓用
        upgradeRewardItemDtos: [],
      },
      rules: {
        paymentNum: [{ required: false, message: "請輸入升等條件-消費次數", trigger: "blur" }],
        level: [{ required: true, message: "請選擇等级", trigger: "blur" }],
        isEnable: [{ required: true, message: "請選擇是否啟用", trigger: "blur" }],
        paymentAmount: [{ required: true, message: "請輸入升等條件-消費金額起", trigger: "blur" }],
        singlePaymentAmount: [{ required: false, message: "請輸入升等條件-單次消費金額", trigger: "blur" }],
        upgradeRewardItemDtos: [{ required: true, message: "請添加升等獎勵", trigger: "blur" },
          { validator: validateLevel, trigger: "blur" },],
      },
      isLook: false,
      couponList: [],
      checkTips: false,
      date: ''
    };
  },
  computed: {
  },
  mounted(){
  },
  beforeDestroy(){
  },
  async created() {
    let queryObj = this.$route.query || {};
    const type = queryObj.type;
    this.type = type;
    await this.getMemberLevelTypeList();
    await this.getTypeSelectList();
    if (type == "create") {
      this.title = "新增貴賓卡等級設定";
    } else if(type == "detail"){
      this.title = "貴賓卡等級設定明細";
      this.isLook = true;
      this.$nextTick(() => {
        this.getDetail(queryObj.id);
      });
    }else if(type == "edit"){
      this.title = "編輯貴賓卡等級設定";
      this.$nextTick(() => {
        this.getDetail(queryObj.id);
      });
    }
  },
  methods: {
    selectType(val,index){
      this.formData.upgradeRewardItemDtos[index].value = null
      // if(val.type === 3){
      //   this.formData.upgradeRewardItemDtos[index].value = 0
      // }
    },
    deleteRow(index, rows) {
      rows.splice(index, 1);
    },
    //新增獎項
    addPrize(){
      this.formData.upgradeRewardItemDtos.push({
        type: "",
        value: "",
        rewardName: "",
      })
    },
    // 獲取優惠券列表
    getTypeSelectList() {
      this.loading = true;
      api.memberLevelSetCouponList().then((res) => {
        if (res.systemCode === 200) {
          this.couponList = res.data;
        }
        this.loading = false;
      });
    },
    getMemberLevelTypeList() {
      this.loading = true;
      api.memberLevelTypeList().then((res) => {
        if (res.systemCode === 200) {
          this.levelList = res.data;
        }
        this.loading = false;
      });
    },
    // 提交表單
    onSubmit() {
      console.log(this.formData)
      // return
      this.checkTips = true;
      this.$refs["cardForm"].validate((valid) => {
        if (valid) {
          let data = this.formData;
          data.upgradeRewardItemDtos.forEach((item)=>{
            item.value = parseInt(item.value);
          })
          const params = {
            id: data.id,
            level: data.level,
            paymentAmount: parseInt(data.paymentAmount),
            singlePaymentAmount: parseInt(data.singlePaymentAmount),
            paymentNum: parseInt(data.paymentNum),
            isEnable: data.isEnable,
            upgradeRewardItemDtos: data.upgradeRewardItemDtos,
          };
          if (this.type == "create"){
            delete params.id
          }
          api.putMemberLevelSet(params).then((res) => {
            if (res.systemCode === 200) {
              this.$message.success("提交成功");
              this.$router.push({ path: "/cms/VIPCardList" });
            } else {
              // this.$message.error(res.message);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //獲取詳情
    getDetail(id) {
      api.memberLevelSetDetail(id).then((res) => {
        if (res.systemCode === 200) {
          const data = res.data;
          this.formData = {
            ...data,
            id: id,
          };
        }
        this.isMore = false;
      });
    },
    // 取消
    cancel() {
      this.$router.push({ path: "/cms/VIPCardList" });
    },
  },
};
</script>

<style lang="scss" scoped>
.title_tip{
  color: rgb(245, 108, 108);
  font-size: 14px;
  line-height: 40px;
  padding-left: 20px;
}
.amount-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  span {
    margin: 0 10px;
  }
}
.questionnaire-edit {
  .formwrap {
    padding-top: 20px;
    padding-left: 20px;
    .el-form-item {
      max-width: 540px;
      .el-select {
        width: 100%;
      }
      &.table_jl{
        max-width: 100%;
      }
    }
    .tips {
      color: #909399;
      font-size: 12px;
      margin-top: 10px;
      &.wrong {
        color: #f56c6c;
      }
    }
    .el-input-number {
      width: 100%;
      /deep/ .el-input__inner {
        text-align: left;
      }
    }
    .el-form-item.shopWrap {
      width: 100%;
      .el-input {
        flex: 1;
        margin-right: 10px;
      }
      /deep/ .el-form-item__content {
        display: flex;
      }
    }
  }
  .searchBox {
    width: 500px;
    margin-bottom: 10px;
    .el-input {
      width: 250px;
      margin-right: 10px;
    }
  }
}
</style>
