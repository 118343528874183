import { getRequest,deleteRequest,putRequest } from "@/http/request_service.js";
import { API } from "@/api/api.js"

export const memberLevelSetList=(params)=>{
    return getRequest(API.memberLevelSet,params);
}
export const putMemberLevelSet=(params)=>{
    return putRequest(API.memberLevelSet,params);
}

export const memberLevelSetDelete=(params)=>{
    return deleteRequest(API.memberLevelSet+'/'+params);
}

export const memberLevelSetDetail=(params)=>{
    return getRequest(API.memberLevelSet+'/'+params);
}

// 獲取等級設定綁定優惠券
export const memberLevelSetCouponList=(params)=>{
    return getRequest(API.memberLevelSetCoupon,params);
}
export const getMemberLevelSetUpgrading=(params)=>{
    return getRequest(API.memberLevelSetUpgrading,params);
}
export const putMemberLevelSetUpgrading=(params)=>{
    return putRequest(API.memberLevelSetUpgrading,params);
}
export const memberLevelTypeList=(params)=>{
    return getRequest(API.memberLevelTypeList,params);
}
