<template>
  <div class="mainwrap questionnaire-edit" v-loading="loading">
    <div class="title">{{ title }}</div>
    <div class="formwrap">
      <el-form
        :model="formData"
        :rules="rules"
        ref="questionnaireForm"
        label-width="150px"
        label-position="left"
      >
        <el-form-item label="品牌：">
          <el-select
            v-model="formData.brand"
            placeholder="請選擇品牌"
            @change="changeBrand"
          >
            <el-option label="請選擇品牌" value=""></el-option>
            <el-option
              v-for="item in allBrandList"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="店別：" class="shopWrap">
          <el-input
            v-model="formData.shopListText"
            disabled
            placeholder="請選擇店別"
          ></el-input>
          <el-button type="primary" :disabled="isMore" @click="openShopDialog"
            >選擇</el-button
          >
        </el-form-item>
        <!-- <el-form-item label="消費金額：" prop="paymentAmount">
          <el-input-number
            v-model="formData.paymentAmount"
            :min="0"
            :controls="false"
            placeholder="請輸入消費金額"
            @change="checkTips = true"
          ></el-input-number>
          <p class="tips" :class="{ wrong: showTips }">
            品牌，店別，消費金額請至少填寫一個
          </p>
        </el-form-item> -->

        <el-form-item label="消費金額：" prop="paymentAmount">
          <div class="amount-section">
            <el-input
              v-model="formData.paymentAmount"
              placeholder="請輸入"
            ></el-input>
            <span>-</span>
            <el-input
              v-model="formData.paymentAmountEnd"
              placeholder="請輸入"
            ></el-input>
          </div>
          <p class="tips" :class="{ wrong: showTips }">
            品牌，店別，消費金額請至少填寫一個
          </p>
        </el-form-item>

        <el-divider></el-divider>
        <el-form-item label="發放獎勵：">
          <el-select
            v-model="formData.couponActivityId"
            multiple
            placeholder="請選擇優惠券"
          >
            <el-option
              v-for="item in couponList"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-divider></el-divider>
        <el-form-item label="開始時間" prop="startTime">
          <el-date-picker
            v-model="formData.startTime"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="選擇開始時間"
            :picker-options="pickerOptions"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="結束時間" prop="endTime">
          <el-date-picker
            v-model="formData.endTime"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="選擇結束時間"
            :picker-options="pickerOptions2"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="啓用狀態" prop="isEnable">
          <el-switch v-model="formData.isEnable"></el-switch>
        </el-form-item>
        <el-form-item label="問卷名稱：" prop="name">
          <el-input
            v-model="formData.name"
            placeholder="請輸入問卷名稱"
          ></el-input>
        </el-form-item>
        <el-form-item label="問卷備註：" prop="description">
          <el-input
            type="textarea"
            v-model="formData.description"
            placeholder="請輸入問卷備注"
          ></el-input>
        </el-form-item>
        <el-form-item label="標題：" prop="messageTitle">
          <el-input
            v-model="formData.messageTitle"
            placeholder="請輸入標題"
          ></el-input>
        </el-form-item>
        <el-form-item label="標簽：" prop="messageSubTitle">
          <el-input
            v-model="formData.messageSubTitle"
            placeholder="請輸入標簽"
          ></el-input>
        </el-form-item>
        <el-form-item label="鏈接：" prop="link">
          <el-input
            v-model="formData.link"
            clearable
            placeholder="請輸入鏈接地址"
            maxlength="200"
          ></el-input>
        </el-form-item>
        <el-form-item label="Hash Key：" prop="hashKey">
          <el-input
            v-model="formData.hashKey"
            clearable
            placeholder="請輸入Webhook解碼Hash Key"
          ></el-input>
        </el-form-item>
        <el-form-item label="IV Key：" prop="ivKey">
          <el-input
            v-model="formData.ivKey"
            clearable
            placeholder="請輸入Webhook解碼IV Key"
          ></el-input>
        </el-form-item>
        <el-form-item label="圖片：" prop="messageListImageUrl">
          <el-upload
            class="img-uploader"
            action=""
            :auto-upload="false"
            :show-file-list="false"
            :on-change="uploadImage"
          >
            <img
              v-if="formData.messageListImageUrl"
              :src="formData.messageListImageUrl"
              class="imgbox"
            />
            <i v-else class="el-icon-plus"></i>
            <div
              @click.stop.prevent
              v-if="formData.messageListImageUrl"
              class="deletewrap"
            >
              <i class="el-icon-delete" @click="removeImage"></i>
            </div>
            <div class="el-upload__tip" slot="tip">
              只能上傳jpg/png文件，且不超過2MB，圖片比例4:3
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
    </div>

    <div class="btnwrap">
      <div class="btnlist">
        <el-button type="primary" @click="onSubmit">提交</el-button>
        <el-button @click="cancel">取消</el-button>
      </div>
    </div>

    <!--選擇店別-->
    <el-dialog
      title="選擇店別"
      @close="hideShopDialog"
      :visible.sync="shopDialog.show"
      width="40%"
    >
      <div class="searchBox">
        <el-input v-model="shopDialog.search"></el-input>
        <el-button type="primary" @click="searchShop">搜索</el-button>
      </div>
      <el-table
        :data="shopData"
        @selection-change="handleSelectionChange"
        row-key="key"
        ref="multipleTable"
      >
        <el-table-column
          type="selection"
          width="100"
          :reserve-selection="true"
        ></el-table-column>
        <el-table-column prop="value" label="名稱"></el-table-column>
      </el-table>
      <div class="bottom">
        <div style="margin-top: 20px">
          <el-button type="primary" @click="submitShopDialog">確定</el-button>
          <el-button @click="hideShopDialog">取消</el-button>
        </div>
        <div class="pagination">
          <el-pagination
            background
            :hide-on-single-page="shopPager.isSingle"
            layout="prev, pager, next,jumper"
            @current-change="changeShopPage"
            :page-size="shopPager.size"
            :current-page="shopPager.current"
            :total="shopPager.total"
          >
          </el-pagination>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as api from "./api";
import { tableDataPagination } from "@/utils/common";

export default {
  data() {
    const validateLink = (rule, value, callback) => {
      let urlReg = new RegExp(
        /^(https?:\/\/(([a-zA-Z0-9]+-?)+[a-zA-Z0-9]+\.)+[a-zA-Z]+)(:\d+)?(\/.*)?(\?.*)?(#.*)?$/
      );
      if (!value) {
        callback(new Error("請輸入鏈接地址"));
      } else if (!urlReg.test(value)) {
        callback(new Error("URL格式錯誤，https://或http://開頭 "));
      }
      callback();
    };
    const validateTime = (rule, value, callback) => {
      const start = this.formData.startTime;
      const end = this.formData.endTime;
      if (!start && rule.field === "startTime") {
        callback(new Error("請選擇開始時間"));
      } else {
        callback();
      }
    };
    var validatePaymentAmount = (rule, value, callback) => {
      if (
        this.formData.paymentAmount !== "" &&
        this.formData.paymentAmount !== null &&
        this.formData.paymentAmountEnd !== "" &&
        this.formData.paymentAmountEnd !== null
      ) {
        if (
          Number(this.formData.paymentAmount) >
          Number(this.formData.paymentAmountEnd)
        ) {
          callback(new Error("请輸入正確的區間"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    return {
      loading: false,
      title: "",
      type: "",
      formData: {
        id: "",
        name: "", // 問卷名稱
        description: "", // 問卷備注
        brand: "", // 品牌
        counter: [], // 店別id
        shopListText: "", // 店別名稱
        startTime: "", // 開始時間
        endTime: "", // 結束時間
        isEnable: true, // 是否啓用
        couponActivityId: [], // 優惠券
        messageTitle: "", // 推播標題
        messageSubTitle: "", // 推播副標題
        messageListImageUrl: "", // 推播列表圖片
        link: "", // 鏈接
        hashKey: "",
        ivKey: "",
        paymentAmount: null, // 消費金額
        paymentAmountEnd: null,
      },
      isMore: false,
      rules: {
        startTime: [
          { required: true, message: "請選擇開始時間", trigger: "blur" },
          { validator: validateTime, trigger: "blur" },
        ],
        endTime: [
          { required: false, message: "請選擇結束時間", trigger: "blur" },
          { validator: validateTime, trigger: "blur" },
        ],
        name: [{ required: true, message: "請輸入問卷名稱", trigger: "blur" }],
        link: [
          { required: true, message: "請輸入鏈接地址", trigger: "blur" },
          { validator: validateLink, trigger: ["change", "blur"] },
        ],
        hashKey: [
          {
            required: true,
            message: "請輸入Webhook解碼Hash Key",
            trigger: "blur",
          },
        ],
        ivKey: [
          {
            required: true,
            message: "請輸入Webhook解碼IV Key",
            trigger: "blur",
          },
        ],
        messageListImageUrl: [
          {
            required: true,
            message: "請上傳圖片",
            trigger: ["change", "blur"],
          },
        ],
        paymentAmount: [
          { validator: validatePaymentAmount, trigger: "change" },
        ],
      },
      allBrandList: [],
      allRestaurantList: [],
      couponList: [],
      // 店別
      shopData: [],
      shopDialog: {
        show: false,
        search: "",
      },
      shopPager: {
        isSingle: false,
        size: 10,
        current: 1,
        total: 0,
      },
      pickerOptions: {
        disabledDate(time) {
          //此条为设置禁止用户选择今天之前的日期，不包含今天。
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        },
      },
      pickerOptions2: {
        disabledDate(time) {
          //此条为设置禁止用户选择今天之前的日期，包含今天。
          return time.getTime() <= Date.now();
        },
      },
      checkTips: false,
    };
  },
  computed: {
    // 品牌，店別，消費金額請至少填寫一個
    showTips() {
      return (
        !this.formData.brand &&
        !this.formData.shopListText &&
        !this.formData.paymentAmount &&
        !this.formData.paymentAmountEnd &&
        this.checkTips
      );
    },
  },
  async created() {
    let queryObj = this.$route.query || {};
    const type = queryObj.type;
    this.type = type;
    await this.getBranchData();
    await this.getShopData();
    await this.getTypeSelectList();
    if (type === "create") {
      this.title = "新增問卷調查";
    } else {
      this.title = "編輯問卷調查";
      this.$nextTick(() => {
        this.getDetail(queryObj.id);
      });
    }
  },
  methods: {
    // 獲取問卷信息
    getDetail(id) {
      api.questionnaireDetail(id).then((res) => {
        if (res.systemCode === 200) {
          const data = res.data;
          // 店別名稱
          this.formData.shopListText = "";
          let shopListText = "";
          if (data.counter && data.counter.length > 0) {
            this.allRestaurantList.forEach((item) => {
              if (data.counter.includes(item.key.toString())) {
                shopListText += item.value + "，";
              }
            });
            shopListText = shopListText.slice(0, -1);
          } else {
            data.counter = [];
          }
          this.formData = {
            ...data,
            couponActivityId: Array.from(new Set(data.couponActivityId)),
            brand: data.brand ? parseInt(data.brand) : "",
            shopListText,
          };
          if (data.brand) this.getShopData(data.brand);
        }
        this.isMore = false;
      });
    },
    // 獲取品牌列表
    getBranchData() {
      api.getBrandBieList().then((res) => {
        if (res.systemCode === 200) {
          this.allBrandList = res.data;
        }
      });
    },
    // 獲取店別列表
    getShopData(Brand = "") {
      let params = {};
      if (Brand) params.BrandId = Brand;
      this.allRestaurantList = [];
      this.isMore = true;
      this.shopVisible = false;
      api.getCounterList(params).then((res) => {
        if (res.systemCode === 200) {
          this.allRestaurantList = res.data;
        }
        this.isMore = false;
      });
    },
    // 獲取優惠券列表
    getTypeSelectList() {
      this.loading = true;
      api.getCouponList().then((res) => {
        if (res.systemCode === 200) {
          this.couponList = res.data;
        }
        this.loading = false;
      });
    },
    // 切換品牌
    changeBrand(e) {
      this.checkTips = true;
      // 清空店別
      this.formData.counter = [];
      this.formData.shopListText = "";
      // 獲取店別
      this.getShopData(e);
    },
    // 打開店別彈窗
    openShopDialog() {
      this.shopPager.current = 1;
      this.getPageRes();
      this.shopDialog.show = true;
      // 清空全選
      this.$nextTick(() => {
        if (this.$refs.multipleTable) {
          if (this.formData.shopListText === "") {
            this.$refs.multipleTable.clearSelection();
          } else if (this.formData.counter.length > 0) {
            this.formData.counter.forEach((key) => {
              const List = this.allRestaurantList.find(
                (item) => item.key == key
              );
              this.$refs.multipleTable.toggleRowSelection(List, true);
            });
          }
        }
      });
    },
    // 取消彈窗
    hideShopDialog() {
      this.shopDialog.show = false;
      this.shopDialog.search = "";
      this.$refs.multipleTable.clearSelection();
    },
    // 確認店別彈窗
    submitShopDialog() {
      this.checkTips = true;
      this.shopDialog.show = false;
      const idArr = [];
      this.formData.shopListText = "";
      if (this.multipleSelection.length > 0) {
        let ctStr = "";
        this.multipleSelection.forEach((item) => {
          ctStr += item.value + "，";
          idArr.push(item.key.toString());
        });
        this.formData.shopListText = ctStr.slice(0, -1);
      }
      this.formData.counter = idArr;
    },
    // 多選店別
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 切換店別分頁
    changeShopPage(val) {
      this.shopPager.current = val;
      this.getPageRes();
    },
    // 搜索店別
    searchShop() {
      this.shopPager.current = 1;
      this.getPageRes();
    },
    // 處理表格數據
    getPageRes() {
      const res = tableDataPagination(
        this.allRestaurantList,
        this.shopPager.current,
        this.shopPager.size,
        this.shopDialog.search
      );
      this.shopData = res.dataList;
      this.shopPager.total = res.dataTotal;
    },
    // 上傳圖片
    async uploadImage(file) {
      if (!this.beforeUpload(file.raw)) return;
      let params = new FormData();
      params.append("file", file.raw);
      await api.uploadImage(params).then((res) => {
        if (res.systemCode === 200) {
          this.formData.messageListImageUrl = res.data;
          this.$message.success("圖片上傳成功");
        }
      });
      this.$refs.questionnaireForm.validateField("messageListImageUrl");
    },
    // 限制格式和大小
    beforeUpload(file) {
      const acceptArr = ["image/jpeg", "image/png"];
      const isJPG = acceptArr.indexOf(file.type) !== -1;
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error("上傳圖片只能是 JPG/PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上傳圖片大小不能超過 2MB!");
      }
      return isJPG && isLt2M;
    },
    // 刪除圖片
    removeImage() {
      this.formData.messageListImageUrl = "";
      this.$refs.questionnaireForm.validateField("messageListImageUrl");
    },
    // 提交表單
    onSubmit() {
      this.checkTips = true;
      this.$refs["questionnaireForm"].validate((valid) => {
        if (valid && !this.showTips) {
          const data = this.formData;
          const params = {
            name: data.name,
            description: data.description,
            brand: data.brand ? data.brand.toString() : "",
            counter: data.counter,
            startTime: data.startTime ? data.startTime : null,
            endTime: data.endTime ? data.endTime : null,
            isEnable: data.isEnable,
            couponActivityId: data.couponActivityId,
            messageTitle: data.messageTitle,
            messageSubTitle: data.messageSubTitle,
            messageListImageUrl: data.messageListImageUrl,
            link: data.link,
            hashKey: data.hashKey,
            ivKey: data.ivKey,
            paymentAmountEnd:
              data.paymentAmountEnd || data.paymentAmountEnd == 0
                ? parseInt(data.paymentAmountEnd, 10)
                : null,
            paymentAmount:
              data.paymentAmount || data.paymentAmount == 0
                ? parseInt(data.paymentAmount, 10)
                : null,
          };
          if (this.type === "edit") params.id = this.formData.id;
          api.editQuestionnaire(params).then((res) => {
            if (res.systemCode === 200) {
              this.$message.success("提交成功");
              this.$router.push({ path: "/cms/questionnaireManagement" });
            } else {
              // this.$message.error(res.message);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 取消
    cancel() {
      this.$router.push({ path: "/cms/questionnaireManagement" });
    },
  },
};
</script>

<style lang="scss" scoped>
.amount-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  span {
    margin: 0 10px;
  }
}
.questionnaire-edit {
  .formwrap {
    padding-top: 20px;
    padding-left: 20px;
    .el-form-item {
      max-width: 500px;
      .el-select {
        width: 100%;
      }
    }
    .tips {
      color: #909399;
      font-size: 12px;
      margin-top: 10px;
      &.wrong {
        color: #f56c6c;
      }
    }
    .el-input-number {
      width: 100%;
      /deep/ .el-input__inner {
        text-align: left;
      }
    }
    .el-form-item.shopWrap {
      width: 100%;
      .el-input {
        flex: 1;
        margin-right: 10px;
      }
      /deep/ .el-form-item__content {
        display: flex;
      }
    }
  }
  .searchBox {
    width: 500px;
    margin-bottom: 10px;
    .el-input {
      width: 250px;
      margin-right: 10px;
    }
  }
}
</style>
