<template>
  <div class="mainwrap restauranlist" v-loading="loading">
    <div class="title">
      貴賓卡等級設定
      <div class="addbtn" v-if="permissionType === 2">
        <el-button type="success" @click="handleEdit('')">新增</el-button>
      </div>
    </div>

    <div class="searchform">
      <el-form
          label-width="120px"
          :inline="true"
          ref="searchForm"
          :model="updateForm"
          class="demo-form-inline"
          style="width: 600px"
      >
        <el-form-item style="width: 480px" label="升等等級規范：">
          <el-input style="width: 350px"
              v-model="updateForm.upgradingStandards"
              placeholder="请輸入升等等級規范"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="updateUrl">更新</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tablewrap">
      <el-table :data="tableData" style="width: 100%" stripe>
        <el-table-column prop="level" label="升等等級"> </el-table-column>
        <el-table-column prop="paymentAmount" label="升等條件-消費金額"> </el-table-column>
        <el-table-column prop="paymentNum" label="升等條件-消費次數"> </el-table-column>
        <el-table-column prop="singlePaymentAmount" label="升等條件-單次消费金额"> </el-table-column>
        <el-table-column prop="isEnable" label="啓用狀態"></el-table-column>
        <el-table-column label="操作" width="220">
          <template slot-scope="scope">
            <el-button v-if="permissionType === 2" @click="handleEdit(scope.row)" type="primary" plain>
              编辑
            </el-button>
<!--            <el-button @click="toDetail(scope.row)" type="info">-->
<!--              明細-->
<!--            </el-button>-->
            <div class="delbtn" v-if="permissionType === 2">
              <el-popconfirm
                  confirm-button-text="確定"
                  cancel-button-text="取消"
                  confirm-button-type="danger"
                  icon="el-icon-info"
                  icon-color="red"
                  title="確定刪除？"
                  @confirm="deleteGame(scope.row.id)"
              >
                <el-button type="danger" slot="reference">刪除</el-button>
              </el-popconfirm>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="pagination">
      <el-pagination
          background
          :hide-on-single-page="isSinglePage"
          layout="prev, pager, next,jumper"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :total="tableDataTotal"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import * as api from "./api";
import { getStore} from '@/utils/storage';
import {getMemberLevelSetUpgrading, memberLevelSetDelete, memberLevelSetList, putMemberLevelSetUpgrading} from "./api";

export default {
  name: "VIPCardList",
  data() {
    return {
      loading: false,
      isSinglePage: false,
      currentPage: 1,
      tableDataTotal: 0,
      updateForm: {
        upgradingStandards: "",
      },

      searchForm: {
        maxResultCount: 10,
      },
      options: [],
      tableData: [],
      permissionType: -1,
      enableOptions: [
        {
          value: true,
          label: "是"
        },
        {
          value: false,
          label: "否"
        },
      ],
    };
  },
  methods: {
    //编辑新增跳转
    handleEdit(item) {
      this.$router.push({
        path: "/cms/VIPCardEdit",
        query: item
            ? {
              type: "edit",
              id: item.id,
            }
            : {
              type: "create",
            },
      });
    },
    toDetail(item){
      this.$router.push({
        path: "/cms/VIPCardEdit",
        query: {
          type: "detail",
          id: item.id,
        }
      });
    },
    // 翻頁
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getList();
    },
    // 更新
    updateUrl() {
      api.putMemberLevelSetUpgrading({
        ...this.updateForm
      })
          .then((res) => {
            if (res.systemCode === 200) {
              this.$message.success("更新成功");
            }
            this.loading = false;
          });
    },
    getUrl(){
      api.getMemberLevelSetUpgrading()
          .then((res) => {
            if (res.systemCode === 200) {
              this.updateForm.upgradingStandards = res.data
            }
          });
    },
    getList() {
      this.loading = true;
      // console.log(this.searchForm.Date);
      api.memberLevelSetList({
        SkipCount: (this.currentPage - 1) * this.searchForm.maxResultCount,
        MaxResultCount: this.searchForm.maxResultCount,
      })
          .then((res) => {
            if (res.systemCode === 200) {
              this.tableData = res.data.items;
              this.tableDataTotal = res.data.totalCount;
            }
            this.loading = false;
          });
    },
    // 刪除
    deleteGame(id) {
      api.memberLevelSetDelete(id).then((res) => {
        if (res.systemCode === 200) {
          this.$message.success("刪除成功");
          //如果刪除項为當前頁最后一项
          if (this.tableData.length <= 1) {
            this.currentPage = this.currentPage > 1 ? this.currentPage - 1 : 1;
          }
          this.getList();
        }
      });
    },
  },
  created() {
    this.getUrl();
    this.getList();
    this.permissionType = parseInt(getStore("permissionType"));
  },
  activated(){
    this.getList();
  }
};
</script>

<style lang="scss" scoped>
.restauranlist {
  .searchform {
    padding: 20px;
    border-bottom: 1px solid #ccc;
  }
  .addbtn {
    width: 150px;
    float: right;
  }

  .delbtn {
    display: inline-block;
    margin-left: 10px;
  }
}
</style>
