<template>
  <div class="mainwrap coupenlist" v-loading="loading">
    <div class="title">
      品牌核銷詳情
      <span>
        <el-button type="primary" plain @click="cancel">返回</el-button>
      </span>
    </div>

    <div class="searchform">
      <el-form
          label-width="82px"
          :inline="true"
          :model="searchForm"
          class="demo-form-inline"
      >
        <el-form-item label="券名：">
          <el-input v-model="searchForm.Name" placeholder="請輸入名稱"></el-input>
        </el-form-item>
        <el-form-item label="活動編號：">
          <el-input v-model="searchForm.No" placeholder="請輸入活動券代碼"></el-input>
        </el-form-item>
        <el-form-item label="品牌：">
          <el-select v-model="searchForm.brandId" placeholder="请选择品牌" @change="changeBrand">
            <el-option
                v-for="item in brandDropList"
                :key="item.key"
                :label="item.value"
                :value="item.key">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="店別：" class="shopWrap">
          <div style="display: flex;">
            <el-input
                v-model="searchForm.shopListText"
                disabled
                placeholder="請選擇店別"
            ></el-input>
            <el-button style="margin-left: 10px;" type="primary" :disabled="isMore" @click="openShopDialog">選擇</el-button>
          </div>
        </el-form-item>
        <el-form-item label="時間：">
          <el-date-picker
              :editable="false"
              v-model="searchForm.Date"
              value-format="yyyy-MM-dd"
              type="daterange"
              start-placeholder="開始時間"
              end-placeholder="結束時間"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch">查询</el-button>
          <el-button @click="reSet">重置</el-button>
          <el-button type="success" :disabled="disabledDown" @click="downLoad">導出</el-button>
        </el-form-item>
      </el-form>
    </div>
<!--    <div class="searchform">-->
<!--      <el-tag class="ml-1 ms1" type="large">名稱：{{name}}</el-tag>-->
<!--      <el-tag class="ml-1 ms1" type="large">類型：{{types}}</el-tag>-->
<!--      <el-tag class="ml-1 ms1" type="success">派發總量：{{totalCount}}</el-tag>-->
<!--      <el-tag class="ml-1 ms1" type="success">已領取數量：{{receivedCount}}</el-tag>-->
<!--      <el-tag class="ml-1 " type="success">核銷數量：{{writeoffCount}}</el-tag>-->
<!--    </div>-->


    <div class="tablewrap">
      <el-table :data="tableData" style="width: 100%" stripe>
        <el-table-column prop="brand" label="品牌"> </el-table-column>
        <el-table-column prop="counter" label="店別"> </el-table-column>
        <el-table-column prop="no" label="活動編號" width="100"> </el-table-column>
        <el-table-column prop="name" label="券名"> </el-table-column>
        <el-table-column prop="code" label="券號"> </el-table-column>
        <el-table-column  prop="receiveTime" label="領取時間"></el-table-column>
        <el-table-column prop="usedTime" label="核銷時間"></el-table-column>
        <el-table-column prop="member" label="會員名稱"></el-table-column>
        <el-table-column prop="paymentAmount" label="消費金額"></el-table-column>
      </el-table>
    </div>

    <div class="pagination">
      <el-pagination
          background
          :hide-on-single-page="isSinglePage"
          layout="prev, pager, next,jumper"
          @current-change="handleCurrentChange"
          :page-size="pageSize"
          :current-page="currentPage"
          :total="tableDataTotal"
      >
      </el-pagination>
    </div>
<!--    <el-dialog title="详情" :visible.sync="dialogTableVisible">-->
<!--      <el-table v-if="dialogTableVisible" :data="openList">-->
<!--        <el-table-column v-for="(val,i,index) in openList[0]" :key="index" :prop="i">-->
<!--          <template slot="header">-->
<!--            {{i}}-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--      </el-table>-->
<!--    </el-dialog>-->

    <!--選擇店別-->
    <el-dialog
        title="選擇店別"
        @close="hideShopDialog"
        :visible.sync="shopDialog.show"
        width="40%"
    >
      <div class="searchBox">
        <el-input v-model="shopDialog.search"></el-input>
        <el-button type="primary" @click="searchShop">搜索</el-button>
      </div>
      <el-table
          :data="shopData"
          @selection-change="handleSelectionChange"
          row-key="key"
          ref="multipleTable"
      >
        <el-table-column
            type="selection"
            width="100"
            :reserve-selection="true"
        ></el-table-column>
        <el-table-column prop="value" label="名稱"></el-table-column>
      </el-table>
      <div class="bottom">
        <div style="margin-top: 20px">
          <el-button type="primary" @click="submitShopDialog">確定</el-button>
          <el-button @click="hideShopDialog">取消</el-button>
        </div>
        <div class="pagination">
          <el-pagination
              background
              :hide-on-single-page="shopPager.isSingle"
              layout="prev, pager, next,jumper"
              @current-change="changeShopPage"
              :page-size="shopPager.size"
              :current-page="shopPager.current"
              :total="shopPager.total"
          >
          </el-pagination>
        </div>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import * as api from "./api";
import { tableDataPagination } from "@/utils/common";
import {brandBieList, brandWriteOffDetailList, exportBrandWriteOffDetailList} from "./api";
export default {
  data(){
    return{
      isMore: false,
      dialogTableVisible:false,
      openList:[],
      loading:false,
      disabledDown:true,
      activityId: '',
      searchForm: {
        Name: "",
        No: "",
        Date: "",
        BrandId: "",
        CounterId: "",
        sorting: '',
        shopListText: "", // 店別名稱
      },
      brandDropList: [],
      tableData:[],
      totalCount:'',
      receivedCount:'',
      writeoffCount:'',
      isSinglePage:false,
      pageSize:10,
      currentPage:1,
      tableDataTotal:0,
      types:'',
      // 店別
      shopData: [],
      shopDialog: {
        show: false,
        search: "",
      },
      shopPager: {
        isSingle: false,
        size: 10,
        current: 1,
        total: 0,
      },
      checkTips: false,
      allRestaurantList: [],
      name:''
    }
  },
  created(){
    let queryObj = this.$route.query || {};
    if (queryObj.type && queryObj.type === "detail" && queryObj.id) {
      this.activityId= queryObj.id;
      this.getBrandDetails(queryObj.id);
      this.getBrandDropList();
    }
  },
  methods:{
    showDis(e){
      this.openList = []
      this.dialogTableVisible = true
      this.openList = JSON.parse(e)
    },
    handleCurrentChange(val){
      this.currentPage=val;
      this.getBrandDetails();
    },
    // 返回上一級
    cancel() {
      this.$router.push({path:"/cms/brandList"});
    },
    onSearch(){
      this.currentPage=1;
      this.getBrandDetails();
    },
    reSet(){
      this.searchForm={
        Mobile:"",
      }
      this.currentPage=1;
      this.getBrandDetails();
    },
    // 下載
    downLoad(index){
      this.disabledDown=true;
      let params={
        activityId:this.activityId,
        no: this.searchForm.No,
        startTime: this.searchForm.Date?this.searchForm.Date[0]:'',
        endTime: this.searchForm.Date?this.searchForm.Date[1]:'',
        brandId: this.searchForm.BrandId,
        name: this.searchForm.Name,
        sorting: this.searchForm.sorting,
        counterId: this.searchForm.CounterId,
        SkipCount:(this.currentPage-1)*this.pageSize,
        MaxResultCount:this.pageSize
      }
      for (let key in params){
        if(params[key]===""){
          delete params[key];
        }
      }
      this.loading = true;
      api.exportBrandWriteOffDetailList(params).then(res=>{
        if(res.systemCode===200){
          window.open(res.data,"_blank")
        }
        this.disabledDown = false;
        this.loading = false;
      })
    },
    //獲取品牌下拉列表
    getBrandDropList(){
      api.brandBieList({}).then(res=>{
        if(res.systemCode===200){
          this.brandDropList = res.data
        }
      })
    },
    // 獲取店別列表
    getShopData() {
      this.allRestaurantList = [];
      this.isMore = true;
      this.shopVisible = false;
      api.getCounterList(this.searchForm.BrandId).then((res) => {
        if (res.systemCode === 200) {
          this.allRestaurantList = res.data;
        }
        this.isMore = false;
      });
    },
    // 切換品牌
    changeBrand(e) {
      this.checkTips = true;
      // 清空店別
      this.searchForm.CounterId = [];
      this.searchForm.shopListText = "";
      // 獲取店別
      this.getShopData(e);
    },
    // 打開店別彈窗
    openShopDialog() {
      this.shopPager.current = 1;
      this.getPageRes();
      this.shopDialog.show = true;
      // 清空全選
      this.$nextTick(() => {
        if (this.$refs.multipleTable) {
          if (this.searchForm.shopListText === "") {
            this.$refs.multipleTable.clearSelection();
          } else if (this.searchForm.CounterId.length > 0) {
            this.searchForm.CounterId.forEach((key) => {
              const List = this.allRestaurantList.find(
                  (item) => item.key == key
              );
              this.$refs.multipleTable.toggleRowSelection(List, true);
            });
          }
        }
      });
    },
    // 取消彈窗
    hideShopDialog() {
      this.shopDialog.show = false;
      this.shopDialog.search = "";
      this.$refs.multipleTable.clearSelection();
    },
    // 確認店別彈窗
    submitShopDialog() {
      this.checkTips = true;
      this.shopDialog.show = false;
      const idArr = [];
      this.searchForm.shopListText = "";
      if (this.multipleSelection.length > 0) {
        let ctStr = "";
        this.multipleSelection.forEach((item) => {
          ctStr += item.value + "，";
          idArr.push(item.key.toString());
        });
        this.searchForm.shopListText = ctStr.slice(0, -1);
      }
      this.searchForm.CounterId = idArr;
    },
    // 多選店別
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 切換店別分頁
    changeShopPage(val) {
      this.shopPager.current = val;
      this.getPageRes();
    },
    // 搜索店別
    searchShop() {
      this.shopPager.current = 1;
      this.getPageRes();
    },
    // 處理表格數據
    getPageRes() {
      const res = tableDataPagination(
          this.allRestaurantList,
          this.shopPager.current,
          this.shopPager.size,
          this.shopDialog.search
      );
      this.shopData = res.dataList;
      this.shopPager.total = res.dataTotal;
    },
    getBrandDetails(){
      this.loading = true;
      this.disabledDown=true;
      let params={
        ActivityId:this.activityId,
        Name:this.searchForm.Name,
        No: this.searchForm.No,
        StartTime:this.searchForm.Date[0],
        EndTime:this.searchForm.Date[1],
        Sorting: this.searchForm.sorting,
        BrandId: this.searchForm.BrandId,
        CounterId: this.searchForm.CounterId ? this.searchForm.CounterId : [],
        SkipCount:(this.currentPage-1)*this.pageSize,
        MaxResultCount:this.pageSize
      }
      for (let key in params){
        if(params[key]==="" || params[key]?.length === 0){
          delete params[key];
        }
      }
      api.brandWriteOffDetailList(params).then(res=>{
        if(res.systemCode===200){
          this.tableData=res.data.items;
          this.tableDataTotal=res.data.totalCount;
          this.loading=false;
          if(this.tableData.length>0){
            this.disabledDown=false;
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.coupenlist {
  .titles {
    display: flex;
    align-items: center;
    .bens {
      margin-right: 10px;
      display: inline-block;
    }
  }
  .searchform {
    // padding: 20px;
    padding: 20px 0px;
    border-bottom: 1px solid #ccc;
    .ms1 {
      margin-right: 10px;
    }
  }
  .delbtn{
    display: inline-block;
    margin-left: 10px;
  }
}

.searchBox {
  width: 500px;
  margin-bottom: 10px;
  .el-input {
    width: 250px;
    margin-right: 10px;
  }
}
</style>
