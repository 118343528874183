import { getRequest,postFormReq,deleteRequest,postRequest } from "@/http/request_service.js";
import { API } from "@/api/api.js"

export const gameActivityList=(params)=>{
    return getRequest(API.gameActivity,params);
}
export const gameActivityDelete=(params)=>{
    return deleteRequest(API.gameActivity+'/'+params);
}

// 獲取品牌
export const getBrandBieList=(params)=>{
    return getRequest(API.brandBieList,params);
}
// 店別列表
export const getCounterList=(params)=>{
    return getRequest(API.shopList,params);
}
// 上傳圖片
export const uploadImage=(params)=>{
    return postFormReq(API.uploadImage,params);
}
// 獲取遊戲綁定優惠券
export const getGameCoupon=(params)=>{
    return getRequest(API.gameCoupon,params);
}
// 新增編輯遊戲設定
export const editGame=(params)=>{
    return postRequest(API.gameEditOrAdd, params);
}
// 獲取遊戲設定詳情
export const gameActivityDetail=(params)=>{
    return getRequest(API.gameActivity+'/'+params);
}
// 獲取遊戲設定詳情
export const gameRecordList=(params)=>{
    return getRequest(API.gameRecord, params);
}
// 遊戲獲取獎項
export const gameReward=(params)=>{
    return getRequest(API.gameActivity+'/'+params + '/game-reward-items', params);
}

//遊戲獎項補發
export const gameReissueAdd=(params)=>{
    return postRequest(API.gameReissue, params);
}

