<template>
  <div class="mainwrap coupenlist" v-loading="loading">
    <div class="title">
      遊戲明細
      <span>
        <el-button type="primary" plain @click="cancel">返回</el-button>
      </span>
    </div>
    <div class="title" style="padding-top: 30px;">
      遊戲獎項
    </div>
    <div class="tablewrap">
      <el-table :data="tableDataPrize" style="width: 100%" stripe>
        <el-table-column prop="proportion" label="比例(總數需是100)"> </el-table-column>
        <el-table-column prop="type" label="獎品內容"> </el-table-column>
        <el-table-column prop="rewardName" label="獎項"> </el-table-column>
        <el-table-column  prop="remainingNum" label="剩餘數量">
          <template slot-scope="scope">
            <span style="padding-right: 20px;">{{scope.row.remainingNum}}</span>
            <el-button v-if="scope.row.isReissue" @click="reissueId = scope.row.id,dialogTableVisible = true" type="primary" plain>
              補發數量
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="title" style="padding-top: 30px;">
      遊戲獲獎記錄
    </div>
    <div class="searchform">
      <el-form
          label-width="90px"
          :inline="true"
          :model="searchForm"
          class="demo-form-inline"
      >
        <el-form-item label="會員姓名：">
          <el-input class="w_50" v-model="searchForm.Lastname" placeholder="請輸入姓"></el-input>
          <el-input class="w_50" v-model="searchForm.Name" placeholder="請輸入名"></el-input>
        </el-form-item>
        <el-form-item label="會員電話：">
          <el-input v-model="searchForm.Mobile" placeholder="請輸入會員電話"></el-input>
        </el-form-item>

        <el-form-item label="獎勵類型：">
          <el-select
              v-model="searchForm.Type"
              placeholder="請選擇獎勵類型"
          >
            <el-option
                v-for="item in prizeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="獎項：">
          <el-input v-model="searchForm.RewardItem" placeholder="請輸入獎項"></el-input>
        </el-form-item>
        <el-form-item label="獲獎時間：">
          <el-date-picker
              :editable="false"
              v-model="searchForm.Date"
              value-format="yyyy-MM-dd"
              type="daterange"
              start-placeholder="開始時間"
              end-placeholder="結束時間"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch">查询</el-button>
          <el-button @click="reSet">重置</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="tablewrap">
      <el-table :data="tableData" style="width: 100%" stripe>
        <el-table-column prop="name" label="會員姓名"> </el-table-column>
        <el-table-column prop="mobile" label="會員手機號"> </el-table-column>
        <el-table-column prop="activity" label="活動名稱"> </el-table-column>
        <el-table-column prop="type" label="獎品類型"> </el-table-column>
        <el-table-column prop="rewardItem" label="獎項"> </el-table-column>
        <el-table-column prop="creationTime" label="獲獎時間"></el-table-column>
      </el-table>
    </div>

    <div class="pagination">
      <el-pagination
          background
          :hide-on-single-page="isSinglePage"
          layout="prev, pager, next,jumper"
          @current-change="handleCurrentChange"
          :page-size="pageSize"
          :current-page="currentPage"
          :total="tableDataTotal"
      >
      </el-pagination>
    </div>
    <el-dialog title="補發遊戲獎項數量" :visible.sync="dialogTableVisible" width="450px">
      <div class="box">
        <el-form
            :model="formData"
            :rules="rules"
            ref="formRules"
            label-width="100px"
            label-position="left"
        >
          <el-form-item label="補發數量：" prop="reissueNum">
            <el-input v-model=" formData.reissueNum" controls-position="right" :min="1" :max="1000" label="請輸入補發數量"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogTableVisible = false">取 消</el-button>
        <el-button type="primary" @click="sureReissue">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import * as api from "./api";
import {gameRecord, gameRecordList, gameReward} from "./api";
export default {
  data(){
    return{
      reissueId: null,
      prizeList: [{
        value: 1,
        label: "點數"
      },{
        value: 2,
        label: "優惠券"
      },{
        value: 3,
        label: "無"
      },],//獎項選擇
      dialogTableVisible:false,
      openList:[],
      loading:false,
      disabledDown:true,
      detailId: null,
      searchForm:{
        Lastname: '',
        Name: '',
        Date: "",
        Mobile:"",
        StartTime: '',
        EndTime: '',
        Type: '',
        RewardItem:'',
      },
      formData: {
        reissueNum: null,
      },
      rules: {
        reissueNum: [{ required: true, message: "請輸入補發數量", trigger: "blur" }]
      },
      tableData:[],
      tableDataPrize: [],
      totalCount:'',
      receivedCount:'',
      writeoffCount:'',
      isSinglePage:false,
      pageSize:10,
      currentPage:1,
      tableDataTotal:0,
      types:'',
      name:''
    }
  },
  created(){
    let queryObj = this.$route.query || {};
    if (queryObj.id) {
      this.detailId = queryObj.id;
      this.getGameReward(queryObj.id);
      this.getGameRecordList(queryObj.id);
    }
  },
  methods:{
    sureReissue(){
      this.$refs["formRules"].validate((valid) => {
        if (valid) {
          this.loading=true;
          const params = {
            id: this.reissueId,
            num: parseInt(this.formData.reissueNum)
          }
          api.gameReissueAdd(params).then((res) => {
            if(res.systemCode===200){
              this.$message.success("補發成功");
              this.getGameReward(this.detailId);
              this.dialogTableVisible = false;
            }
            this.loading=false;
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleCurrentChange(val){
      this.currentPage=val;
      this.getGameRecordList(this.detailId);
    },
    // 返回上一級
    cancel() {
      this.$router.push({path:"/cms/gameList"});
    },
    // getRefreshActivity() {
    //   this.loading=true;
    //   api.getRefreshActivity().then(res=> {
    //     if(res.data){
    //       this.getCouponList();
    //     }
    //     this.loading=false;
    //   })
    // },
    onSearch(){
      this.currentPage=1;
      this.getGameRecordList(this.detailId);
    },
    reSet(){
      this.searchForm={
        Date: "",
        Mobile:"",
        StartTime: '',
        EndTime: '',
        Type: '',
        RewardItem:'',
      }
      this.currentPage = 1;
      this.getGameRecordList(this.detailId);
    },
    getGameReward(id) {
      api.gameReward(id).then(res=>{
        if(res.systemCode===200){
          this.tableDataPrize = res.data;
        }
      }).catch((error) => {
        console.error(error);
      });
    },
    getGameRecordList(id){
      this.loading=true;
      api.gameRecordList({
        ActivityId:id,
        Lastname: this.searchForm.Lastname,
        Name: this.searchForm.Name,
        Mobile:this.searchForm.Mobile,
        StartTime: this.searchForm.Date[0] ? this.searchForm.Date[0] : "",
        EndTime: this.searchForm.Date[1] ? this.searchForm.Date[1] : "",
        Type: this.searchForm.Type,
        RewardItem: this.searchForm.RewardItem,
        SkipCount:(this.currentPage-1)*this.pageSize,
        MaxResultCount:this.pageSize
      }).then(res=>{
        if(res.systemCode===200){
          this.tableData= res.data.items;
          this.tableDataTotal=res.data.totalCount;
        }
        this.loading=false;
      }).catch((error) => {
        console.error("错误");
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.coupenlist {
  .titles {
    display: flex;
    align-items: center;
    .bens {
      margin-right: 10px;
      display: inline-block;
    }
  }
  .searchform {
    // padding: 20px;
    padding: 20px 0px;
    border-bottom: 1px solid #ccc;
    .w_50{
      width: 50%;
    }
    .ms1 {
      margin-right: 10px;
    }
  }
  .delbtn{
    display: inline-block;
    margin-left: 10px;
  }
}
</style>
